'use strict';

angular.module('mvpcarrefourApp')
  .config(function ($stateProvider) {


    $stateProvider
      .state('main.countdown', {
        url: 'contador-premios-innovacion-carrefour-2024',
        views: {
          'main': {
            templateUrl: 'app/statics/countdown.html',
            controller: 'StaticsCtrl'
          }
        }
      })
      .state('main', {
        url: '/',
        templateUrl: 'app/main/main.html',
        controller: 'MainController',
        controllerAs: 'main',
        resolve: {
          template: ['Router', function (Router) {
            return Router.getAlternativeTemplate('main');
          }],
          currentUser: ['$rootScope', 'Auth', function ($rootScope, Auth) {
            return Auth.getCurrentUser(user => {
              $rootScope.currentUser = user;
            })
          }]
        }
      })
      .state('main.vote', {
        url: 'votacion',
        resolve: {
          _categories: ['Category', function (Category) {
            return Category.list();
          }]
        },
        views: {
          'main': {
            templateUrl: 'app/category/vote.html',
            controller: function ($state, _categories) {
              /*Solo hay que obligar a navegacion con una categoria aleatoria
              en caso de ser el estado main.vote si llega un estado hijo la
              navegacion se produce directamente
               */
              if ($state.current.name === 'main.vote')
                $state.go('.category', {slugCategory: $state.params.slugCategory || _.sample(_categories).slug});
            }
          }
        }
      })

      .state('main.categories', {
        url: 'categorias',
        resolve: {
          _categories: ['Category', function (Category) {
            return Category.list();
          }]
        },
        views: {
          'main': {
            templateUrl: 'app/category/categories.html',
            controller: 'AllCategories'

          }
        }
      })
      .state('main.cookies', {
        url: 'politica-de-cookies',
        views: {
          'main': {
            templateUrl: 'app/statics/cookies.html',
            controller: 'StaticsCtrl'
          }
        }
      })

      .state('main.cookiescarrefour', {
        url: 'politica-de-cookies/mas-info/info-01.aspx',
      })


      .state('main.legal', {
        url: 'condiciones-de-uso',
        views: {
          'main': {
            templateUrl: 'app/statics/condiciones-de-uso.html',
            controller: 'StaticsCtrl'
          }
        }
      })
      .state('main.privacity', {
        url: 'politica-de-privacidad',
        views: {
          'main': {
            templateUrl: 'app/statics/privacity.html',
            controller: 'StaticsCtrl'
          }
        }
      })
      .state('main.edition2015', {
        url: 'edicion-2015',
        views: {
          'main': {
            templateUrl: 'app/statics/edition2015.html',
            controller: 'StaticsCtrl'
          }
        }
      })
      .state('main.edition2016', {
        url: 'edicion-2016',
        views: {
          'main': {
            templateUrl: 'app/statics/edition2016.html',
            controller: 'StaticsCtrl'
          }
        }
      })
      .state('main.edition2017', {
        url: 'edicion-2017',
        views: {
          'main': {
            templateUrl: 'app/statics/edition2017.html',
            controller: 'StaticsCtrl'
          }
        }
      })
      .state('main.edition2018', {
        url: 'edicion-2018',
        views: {
          'main': {
            templateUrl: 'app/statics/edition2018.html',
            controller: 'StaticsCtrl'
          }
        }
      })
      .state('main.edition2019', {
        url: 'edicion-2019',
        views: {
          'main': {
            templateUrl: 'app/statics/edition2019.html',
            controller: 'StaticsCtrl'
          }
        }
      })
  .state('main.edition2020', {
      url: 'edicion-2020',
      views: {
        'main': {
          templateUrl: 'app/statics/edition2020.html',
          controller: 'StaticsCtrl'
        }
      }
    })
      .state('main.edition2021', {
      url: 'edicion-2021',
      views: {
        'main': {
          templateUrl: 'app/statics/edition2021.html',
          controller: 'StaticsCtrl'
        }
      }
    })
      .state('main.edition2022', {
        url: 'edicion-2022',
        views: {
          'main': {
            templateUrl: 'app/statics/edition2022.html',
            controller: 'StaticsCtrl'
          }
        }
      })
      .state('main.edition2023', {
        url: 'edicion-2023',
        views: {
          'main': {
            templateUrl: 'app/statics/edition2023.html',
            controller: 'StaticsCtrl'
          }
        }
      })
      .state('main.editions', {
        url: 'ediciones-anteriores-premios-innovacion-carrefour',
        views: {
          'main': {
            templateUrl: 'app/statics/previous-editions.html',
            controller: 'StaticsCtrl'
          }
        }
      })
      .state('main.materials', {
        url: 'material-premios-a-la-innovacion-2024',
        views: {
          'main': {
            templateUrl: 'app/statics/materials.html',
            controller: 'StaticsCtrl'
          }
        }
      })
      .state('main.blog', {
        url: 'blog',
        views: {
          'main': {
            templateUrl: 'app/statics/blog.html',
            controller: 'StaticsCtrl'
          }
        }
      })
      .state('main.404', {
        url: '404',
        views: {
          'main': {
            templateUrl: 'app/statics/404.html',
            controller: 'StaticsCtrl'
          }
        }
      })
      .state('main.concurso', {
        url: 'concurso',
        views: {
          'main': {
            templateUrl: 'app/statics/concurso.html',
            controller: 'StaticsCtrl'
          }
        }
      })
      .state('main.innovacion', {
        url: 'concurso-innovacion',
        views: {
          'main': {
            templateUrl: 'app/statics/concurso-innovacion.html',
            controller: 'StaticsCtrl'
          }
        }
      })

      .state('main.bbllsorteo', {
        url: 'bases-legales-sorteo',
        views: {
          'main': {
            templateUrl: 'app/statics/bases-legales-sorteo.html',
            controller: 'StaticsCtrl'
          }
        }
      })


      .state('main.bbllreclutadores', {
        url: 'bases-legales-reclutadores',
        views: {
          'main': {
            templateUrl: 'app/statics/bases-legales-reclutadores.html',
            controller: 'StaticsCtrl'
          }
        }
      })


      .state('main.bbllinnovando', {
        url: 'bases-legales-innovando',
        views: {
          'main': {
            templateUrl: 'app/statics/bases-legales-innovando.html',
            controller: 'StaticsCtrl'
          }
        }
      })

      .state('main.bbllpremios', {
        url: 'bases-legales-premios',
        views: {
          'main': {
            templateUrl: 'app/statics/bases-legales-premios.html',
            controller: 'StaticsCtrl'
          }
        }
      })

      .state('main.winners', {
        url: 'ganadores-premios-innovacion-carrefour-2017',
        views: {
          'main': {
            templateUrl: 'app/statics/ganadores.html',
            controller: 'StaticsCtrl'
          }
        }
      })

      .state('main.finalists', {
        url: 'finalistas-premios-innovacion-carrefour-2024',
        views: {
          'main': {
            templateUrl: 'app/statics/finalistas.html',
            controller: 'StaticsCtrl'
          }
        }
      })

      .state('main.misvotos', {
        url: 'mis-votos',
        views: {
          'main': {
            templateUrl: 'app/myvotes/myvotes.html',
            controller: 'MyVotesController'
          }
        }
      })

      .state('main.ganadores-rrss', {
        url: 'ganadores-rrss',
        views: {
          'main': {
            templateUrl: 'app/statics/ganadores-rrss.html',
            controller: 'StaticsCtrl'
          }
        }
      })

      .state('main.ganadores', {
        url: 'ganadores',
        views: {
          'main': {
            templateUrl: 'app/statics/ganadores.html',
            controller: 'StaticsCtrl'
          }
        }
      })

  });
